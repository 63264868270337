import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

import "./index.scss"
import BuildAnything from "./build-anything"
import FromIdeaToProd from "./from-idea-to-prod"
import Implementation from "./implementation"
import Widget from "./home-widget"
import Presentation from "./presentation"
import Section6 from "./section6"
import SliderOFPartners from "./slider-of-partners"
import Testimonials from "./testimonials"
import Why from "./why"
import WhyArgs from "./why-args"
import CuriousYet from "./curious-yet"

const Home = () => {
  let pageurl = ""
  typeof window !== "undefined" && (pageurl = window.location.href)

  return (
    <main>
      <Helmet>
        <title>Maximo Mobile | MAXapps IBM Maximo Mobile Partners</title>
        <meta name="robots" content="index, follow" />.
        <meta name="rights" content="© Copyright 2022 MAXapps Maximo Mobile" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageurl} />
        <meta property="og:site_name" content="maxapps-maximo" />
        <meta property="og:locale" content="en-US" />
        <meta
          id="og-title"
          property="og:title"
          content={"Maximo Mobile | MAXapps IBM Maximo Mobile Partners"}
        />
        <meta
          id="meta-description"
          name="description"
          content="IBM Maximo Mobile Solution for Your Enterprise. Build Powerful, Secure, Flexible and Versatile Maximo Mobile EAM Applications. MAXapps provides IBM Maximo teams with a flexible tool for generating dynamic mobile applications that can suit any business requirements."
        />
        <meta
          id="og-image"
          property="og:image"
          content="https://maxapps-maximo.com/og/homepage.png"
        />
        <meta
          property="twitter:image:src"
          content="https://maxapps-maximo.com/og/homepage.png"
        />
        <meta
          property="twitter:image"
          content="https://maxapps-maximo.com/og/homepage.png"
        />
        <link
          rel="canonical"
          key="https://www.maxapps-maximo.com"
          href="https://maxapps-maximo.com"
        />
      </Helmet>
      <Presentation />
      <SliderOFPartners />
      <Why />
      <WhyArgs />
      <Testimonials />
      <BuildAnything />
      <FromIdeaToProd />
      <Widget />
      <div
        id="carouselIndicators"
        className="d-none carousel carouselHome slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="p-4 carousel-item active">
            <Section6 />
          </div>

          <div className="p-4 carousel-item">
            <Implementation />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselIndicators"
          data-bs-slide="prev"
        >
          <span className=" carousel-control-prev-icon" aria-hidden="true">
            <svg viewBox="0 0 451.847 451.847">
              <path
                d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
        c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
        c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"
              ></path>
            </svg>
          </span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselIndicators"
          data-bs-slide="next"
        >
          <span className="  carousel-control-next-icon" aria-hidden="true">
            <svg viewBox="0 0 451.846 451.847">
              <path
                d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
        L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
        c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"
              ></path>
            </svg>
          </span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="sect6implementation row p-2 pt-5 sect-6">
        <Section6 />
        <Implementation />
      </div>
      <CuriousYet />
    </main>
  )
}

export default Home
