import React from "react"
import { Link } from "gatsby"
import "./index.scss"
const WhiteButton = ({ data, navigateTo }) => {
  return (
    <div id="WhiteButton" className="WhiteButton" data-testid="WhiteButton">
      <Link to={navigateTo} className="linkStyle" data-testid="link">
        <button className="white-btn">{data}</button>
      </Link>
    </div>
  )
}

export default WhiteButton
