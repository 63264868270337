import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const SliderOFPartners = ({ _data }) => {
  let data = []
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(slider-of-partners)/" }) {
          exports {
            data {
              src
            }
          }
        }
      }
    `)
    data = result.mdx.exports.data
  } catch (error) {
    data = _data
  }
  return (
    <div
      id="SliderOFPartners"
      className="SliderOFPartners sect text-center"
      data-testid="SliderOFPartners"
    >
      <p className="title">Trusted by</p>
      <div className="content">
        <div className="wrapper  d-flex flex-row">
          <div className="slide-trackone d-flex flex-row">
            {data?.map((item, index) => {
              return <img src={item.src} key={index} alt="" className="" />
            })}
          </div>
          <div className="slide-tracktwo d-flex flex-row">
            {data?.map((item, index) => {
              return <img src={item.src} key={index} alt="" className="" />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderOFPartners
