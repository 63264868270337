import React, { useEffect, createRef } from "react"

import lottie from "lottie-web"
import animationData from "../../animations/drag_drop.json"

const AnimatedSection = () => {
  let animationContainer = createRef()
  // let anim = null;
  useEffect(() => {
    let anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [animationContainer])

  return (
    <div className="AnimatedSection" data-testid="AnimatedSection">
      <div className="animation-container" ref={animationContainer} />
    </div>
  )
}

export default AnimatedSection
