import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import { StaticImage } from "gatsby-plugin-image"
import { Modal } from "react-bootstrap"
import YouTube from "react-youtube"

import "./index.scss"
import YellowButton from "../buttons/yellow-button"

const BuildAnything = ({ _data }) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(build-anything)/" }) {
          frontmatter {
            title
            buttonTitle
            buttonSize
            image
            description
          }
        }
      }
    `)
    data = result.mdx.frontmatter
  } catch (error) {
    data = _data
  }

  const [showModal, setShowModal] = useState(false)

  const videoopts = {
    height: "598",
    playerVars: {
      autoplay: 1,
    },
  }

  return (
    <div
      id="BuildAnything"
      className="row  buildAnything align-items-center"
      data-testid="BuildAnything"
    >
      <div className="col-12 col-lg-6 text-center text-md-start pt-5 mb-5">
        <h2
          className="title"
          dangerouslySetInnerHTML={{ __html: data?.title }}
        ></h2>

        <p
          className="description pb-2"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        ></p>

        <div className="row text-center text-lg-start ">
          <YellowButton
            data={data?.buttonTitle}
            children={
              <BsArrowRight size={20} className="yellow-btn-arrow ms-2" />
            }
            size={data?.buttonSize}
          />
        </div>
      </div>
      <div
        className="col-12 col-lg-6 text-center text-md-end p-0 m-0 img-container"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <StaticImage
          src="../../../../static/buildAnything.png"
          alt="IBM Mobile application built with MAXapps"
          title="IBM Mobile application built with MAXapps"
          placeholder="tracedSVG"
          data-testid="image"
          className="img-fluid p-0 m-0"
        />
      </div>

      <Modal
        size="xl"
        dialogClassName="video-modal"
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className=" text-end">
          <i
            onClick={() => {
              setShowModal(false)
            }}
            className="bi bi-x-lg close-video "
          ></i>
        </div>
        <Modal.Body>
          <YouTube
            videoId="XUPVzTYlJ88"
            opts={videoopts}
            onEnd={() => setShowModal(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default BuildAnything
