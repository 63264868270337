import React from "react"

import Home from "../components/home"
import Layout from "../components/layout/t1/layout"

const IndexPage = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}

export default IndexPage
