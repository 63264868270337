import { Link } from "gatsby"
import React from "react"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"

const WhyArgs = () => {
  let dataInfo = {}
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(why-args)/" }) {
          exports {
            whyargs {
              description
              icon
              name
              link
            }
          }
        }
      }
    `)
    dataInfo = data.mdx.exports.whyargs
  } catch (error) {
    dataInfo = {}
  }
  const argslist = dataInfo.slice(1)

  return (
    <div id="WhyArgs" className=" justify-content-between WhyArgs ">
      <div className="row bs-card-hover-zoom">
        <div
          id="carouselWhyArgs"
          className="d-none carousel slide"
          data-interval="false"
        >
          <div className="carousel-inner">
            <div className="mt-3 carousel-item active">
              <div className=" px-5 col-12 py-3">
                <div className="card">
                  <div className="card-body p-4">
                    <h3>{dataInfo[0].name}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dataInfo[0].description,
                      }}
                    ></p>
                    <span className="d-none col-12">
                      <Link type="button" to={dataInfo[0].link} className="btn">
                        Read more
                      </Link>
                    </span>
                    <img
                      alt={`IBM MAXIMO: ${dataInfo[0].name}`}
                      title={`IBM MAXIMO: ${dataInfo[0].name}`}
                      src={dataInfo[0].icon}
                    />
                  </div>
                </div>
              </div>
            </div>

            {argslist?.map((data, index) => {
              return (
                <div className="mt-3 carousel-item" key={index}>
                  <div className=" px-5 col-12 py-3">
                    <div className="card">
                      <div className="card-body p-4">
                        <h3>{data.name}</h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        <span className="d-none col-12">
                          <Link type="button" to={data.link} className="btn">
                            Read more
                          </Link>
                        </span>
                        <img
                          alt={`IBM MAXIMO: ${data.name}`}
                          title={`IBM MAXIMO: ${data.name}`}
                          src={data.icon}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselWhyArgs"
            data-bs-slide="prev"
          >
            <span className=" carousel-control-prev-icon" aria-hidden="true">
              <svg viewBox="0 0 451.847 451.847">
                <path
                  d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
        c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
        c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"
                ></path>
              </svg>
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselWhyArgs"
            data-bs-slide="next"
          >
            <span className="  carousel-control-next-icon" aria-hidden="true">
              <svg viewBox="0 0 451.846 451.847">
                <path
                  d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
        L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
        c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"
                ></path>
              </svg>
            </span>
            <span className="visually-hidden">Next</span>
          </button>
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselWhyArgs"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 0"
            ></button>

            {argslist?.map((data, index) => {
              return (
                <button
                  type="button"
                  data-bs-target="#carouselWhyArgs"
                  data-bs-slide-to={index + 1}
                  aria-label={`Slide ${index + 2}`}
                  key={index}
                >
                  {index + 1}
                </button>
              )
            })}
          </div>
        </div>

        {dataInfo?.map((data, index) => {
          return (
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-6 py-1 cards"
              key={index}
            >
              <div className="card">
                <div className="card-body p-4">
                  <h3>{data.name}</h3>
                  <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
                  <span className="d-none col-12">
                    <Link type="button" to={data.link} className="btn">
                      Read more
                    </Link>
                  </span>
                  <img
                    alt={`IBM MAXIMO: ${data.name}`}
                    title={`IBM MAXIMO: ${data.name}`}
                    src={data.icon}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default WhyArgs
