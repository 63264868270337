import React from "react"
import Main from "./Main"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"

const Testimonials = ({ _data, _title }) => {
  let title = ""
  let dataInfo = {}
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(testimonials)/" }) {
          exports {
            testimonials {
              description
              icon
              name
              position
            }
          }
          frontmatter {
            titleTestomonial
          }
        }
      }
    `)
    dataInfo = data.mdx.exports.testimonials
    title = data.mdx.frontmatter.titleTestomonial
  } catch (error) {
    dataInfo = _data
    title = _title
  }
  const dataOthers = dataInfo.slice(2)
  const datamobile = dataInfo.slice(1)

  return (
    <div className="testimonials">
      <div
        id="carouselExampleCaptions"
        className=" d-none d-lg-block carousel sect slide pt-5 "
        data-bs-ride="carousel"
        data-testid="Testimonials"
      >
        <h2 className="" dangerouslySetInnerHTML={{ __html: title }}></h2>

        <div className=" carousel-indicators">
          {(function (indicators) {
            for (let index = 0; index < dataInfo.length / 2; index += 1) {
              indicators.push(
                <div key={"div" + index} className="px-3">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to={`${index}`}
                    className={index === 0 ? "active  " : ""}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label={`Slide ${index}`}
                    key={index}
                  ></button>
                </div>
              )
            }
            return indicators
          })([])}
        </div>
        <div className=" sect container">
          <div className="row">
            <div className=" carousel-inner" data-testid="carousel">
              <div className=" carousel-item active">
                <div className="row">
                  <Main
                    title1={dataInfo[0].name}
                    title2={dataInfo[0].position}
                    subtitle={dataInfo[0].description}
                    icon={dataInfo[0].icon}
                  />
                  <Main
                    title1={dataInfo[1].name}
                    title2={dataInfo[1].position}
                    subtitle={dataInfo[1].description}
                    icon={dataInfo[1].icon}
                  />
                </div>
              </div>

              {(function (rows) {
                for (let i = 0; i < dataOthers.length; i += 2) {
                  rows.push(
                    <div className="carousel-item " key={i + 1}>
                      <div className="row ">
                        <Main
                          title1={dataOthers[i]?.name}
                          title2={dataOthers[i]?.position}
                          subtitle={dataOthers[i]?.description}
                          icon={dataOthers[i]?.icon}
                        />
                        <Main
                          title1={dataOthers[i + 1]?.name}
                          title2={dataOthers[i + 1]?.position}
                          subtitle={dataOthers[i + 1]?.description}
                          icon={dataOthers[i + 1]?.icon}
                        />
                      </div>
                    </div>
                  )
                }
                return rows
              })([])}
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span className=" carousel-control-prev-icon" aria-hidden="true">
              <svg viewBox="0 0 451.847 451.847">
                <path
                  d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
          c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
          c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"
                ></path>
              </svg>
            </span>

            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className=" carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span className="  carousel-control-next-icon" aria-hidden="true">
              <svg viewBox="0 0 451.846 451.847">
                <path
                  d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
          L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
          c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"
                ></path>
              </svg>
            </span>
            )<span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div
        id="carouselTestimonialsMobile"
        className=" d-block d-lg-none  carousel slide carouselmobile "
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {dataInfo?.map((elt, index) => {
            return (
              <button
                type="button"
                data-bs-target="#carouselTestimonialsMobile"
                data-bs-slide-to={`${index}`}
                className={index === 0 ? "active  " : ""}
                aria-current={index === 0 ? "true" : "false"}
                aria-label={`Slide ${index}`}
                key={index}
              ></button>
            )
          })}
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <Main
              title1={dataInfo[0].name}
              title2={dataInfo[0].position}
              subtitle={dataInfo[0].description}
              icon={dataInfo[0].icon}
            />
          </div>
          {datamobile?.map((elt, index) => {
            return (
              <div className="carousel-item" key={index}>
                <Main
                  title1={elt.name}
                  title2={elt.position}
                  subtitle={elt.description}
                  icon={elt.icon}
                />
              </div>
            )
          })}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselTestimonialsMobile"
          data-bs-slide="prev"
        >
          <span className=" carousel-control-prev-icon" aria-hidden="true">
            <svg viewBox="0 0 451.847 451.847">
              <path
                d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
          c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
          c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"
              ></path>
            </svg>
          </span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselTestimonialsMobile"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true">
            <svg viewBox="0 0 451.846 451.847">
              <path
                d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
          L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
          c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"
              ></path>
            </svg>
          </span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

export default Testimonials
