import React from "react"
import "./index.scss"

const Main = props => {
  const { subtitle, title2, title1, icon } = props

  return (
    <div
      className="col-12 col-lg-6 justify-content-center pb-5 testimonials"
      data-testid="Main"
    >
      <div className="col-5 col-lg-6 text-left slide-image">
        {icon && (
          <img
            src={icon}
            alt={`IBM Maximo: ${title1}`}
            title={`IBM Maximo: ${title1}`}
            className="m-0 p-0 img-fluid"
            width="130"
          />
        )}
      </div>
      <div className="col-12 col-lg-10 ">
        <div className="pb-2">
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></p>
        </div>
        <div className="row pb-5">
          <div className="col-7 col-lg-6 text-start">
            <p className="m-0 title1">{title1}</p>
            <p className="m-0 title2">{title2}</p>
          </div>
        </div>
        {/* <div className="d-block d-sm-none pb-5">
          <div className=" ">
            <img src={icon} alt="#" className="m-0 p-0 img-fluid" width="200" />
          </div>
          <div className="text-center  ">
            <p className="m-0 title1">{title1}</p>
            <p className="m-0 title2">{title2}</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Main
