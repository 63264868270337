import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"

const Implementation = ({ _data }) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(implementation)/" }) {
          frontmatter {
            title
            subTitle
            image
          }
        }
      }
    `)
    data = result.mdx.frontmatter
  } catch (error) {
    data = _data
  }

  return (
    <div className="col-lg-6 carditem col-12 p-3">
      <div
        id="Implementation"
        className="Implementation "
        data-testid="CardItem"
      >
        <div className="titleContainer  row">
          <div className="title col-9">
            <h2
              className="titleStyle"
              dangerouslySetInnerHTML={{ __html: data?.title }}
            ></h2>
          </div>

          <div className="image col-3 ">
            <img
              alt={`maxapps maximo: Easy Implementation Process`}
              title={`maxapps maximo: Easy Implementation Process`}
              src="secure.png"
              className="imageStyle p-0"
              data-testid="image"
            />
          </div>
        </div>

        <div className="pargContainer ">
          <div className="col-12 col-sm-9">
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: data?.subTitle }}
            ></p>
          </div>
        </div>
        <div className="btnContainer mt-3 py-2 ">
          <div className="">
            <Link type="button" to="/get-started/" className="btn">
              Read more
              <BsArrowRight className="how-card-arrow" size={20} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Implementation
