import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import { StaticImage } from "gatsby-plugin-image"

import "./index.scss"
const Widget = ({ _data }) => {
  let widget = {}
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(home-widget)/" }) {
          frontmatter {
            widgetSubTitle
            title
            link
            linkTo
          }
        }
      }
    `)
    widget = data.mdx.frontmatter
  } catch (error) {
    widget = _data
  }
  return (
    <div id="Widget" className="  Widget pt-5" data-testid="Widget">
      <div className="col-12  text-center  pt-5 mb-5">
        <h2
          className="col-12  text-center"
          dangerouslySetInnerHTML={{ __html: widget?.title }}
        ></h2>
        <p dangerouslySetInnerHTML={{ __html: widget?.widgetSubTitle }}></p>
      </div>
      <div className="col-12  row p-0 m-0 justify-content-center">
        <StaticImage
          src="../../../../static/widgets.png"
          alt="MAXapps widgets for IBM Maximo"
          title="MAXapps widgets for IBM Maximo"
          className="img-fluid p-0 m-0 py-lg-5"
          placeholder="tracedSVG"
        />
      </div>

      <div className="row text-center ">
        <span>
          <Link to={widget?.linkTo} className="linkStyle" data-testid="link">
            {widget.link}{" "}
            <BsArrowRight className="home-widget-arrow" size={20} />
          </Link>
        </span>
      </div>
    </div>
  )
}

export default Widget
