import React from "react"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
const CuriousYet = ({ _data }) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(curious-yet)/" }) {
          frontmatter {
            title
            subtitle
            titleButton
          }
        }
      }
    `)
    data = result.mdx.frontmatter
  } catch (error) {
    data = _data
  }
  return (
    <div
      id="CuriousYet"
      className="CuriousYet sect justify-content-center"
      data-testid="CuriousYet"
    >
      <div className="col-12 emptydiv"></div>

      <div className="col-12 col-md-7  text-center  whitegradien">
        <div className=" text-center ">
          <h2>{data?.title}</h2>
        </div>
        <button type="button" className="btn">
          <Link to="/get-started/" className="link">
            {data?.titleButton}
            <BsArrowRight size={20} className="arrow-btn" />
          </Link>
        </button>
      </div>
    </div>
  )
}

export default CuriousYet
