import React from "react"
import { BsArrowRight } from "react-icons/bs"
import { useStaticQuery, graphql, Link } from "gatsby"
import AnimatedSection from "../../animated-section"
import "./index.scss"

const FromIdeaToProd = ({ _data }) => {
  let dataInfo = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(from-idea-to-prod)/" }) {
          frontmatter {
            title
            description
            link
            to
          }
        }
      }
    `)
    dataInfo = result.mdx.frontmatter
  } catch (error) {
    dataInfo = _data
  }

  return (
    <div
      id="FromIdeaToProd"
      className=" fromIdeaToProd row pt-5"
      data-testid="FromIdeaToProd"
    >
      <div className="col-12  text-center  pt-5 mb-5">
        <h2 dangerouslySetInnerHTML={{ __html: dataInfo?.title }}></h2>

        <p
          className="pb-2"
          dangerouslySetInnerHTML={{ __html: dataInfo?.description }}
        ></p>
      </div>
      <div className="justify-content-center col-12 ">
        <div className="col-10  text-center ">
          <AnimatedSection />
        </div>
      </div>

      <div className="justify-content-center col-12 ">
        <span>
          <Link to={dataInfo?.to} className="linkStyle" data-testid="link">
            {dataInfo?.link}
            <BsArrowRight className="idea-prod-arrow" size={20} />
          </Link>
        </span>
      </div>
    </div>
  )
}

export default FromIdeaToProd
