import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const Why = ({ _data }) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(why-home)/" } }) {
          nodes {
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    `)
    data = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    data = _data
  }
  return (
    <div id="Why" className="sect why" data-testid="Why">
      <div className="col-12 text-center text-center pt-5 mb-5 ">
        <h2 dangerouslySetInnerHTML={{ __html: data.title }}></h2>
        <p dangerouslySetInnerHTML={{ __html: data.subtitle }}></p>
      </div>
    </div>
  )
}

export default Why
