import React from "react"
import { BsArrowRight } from "react-icons/bs"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./index.scss"
import YellowButton from "../buttons/yellow-button"
import WhiteButton from "../buttons/white-button"

const Presentation = ({ _data }) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(presentations)/" } }) {
          nodes {
            frontmatter {
              link
              image
              titleButton
              description
              title1
              title2
              subTitle1
              subTitle2
              subTitle3
            }
          }
        }
      }
    `)
    data = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    data = _data
  }

  return (
    <div
      id="Presentation"
      className="row sect presentation align-items-center"
      data-testid="Presentation"
    >
      <div className="col-12 col-lg-6 text-center text-lg-start presentation-s1">
        <h1 className="title">
          <span dangerouslySetInnerHTML={{ __html: data.title1 }}></span>
          <span className="span "> {data.title2}</span>
        </h1>
        <h2>
          {data.subTitle1}
          <span> {data.subTitle2} </span>
          <span
            className="spanblack"
            dangerouslySetInnerHTML={{ __html: data.subTitle3 }}
          ></span>
        </h2>
        <p
          className="pb-2"
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></p>

        <div className="row">
          <span className="presentation-btns">
            <YellowButton
              data={data.titleButton}
              children={
                <BsArrowRight size={20} className="yellow-btn-arrow ms-2" />
              }
              link="/docs/Tutorials/"
            />
            <WhiteButton data={data.link} navigateTo="/docs/" />
          </span>
        </div>
      </div>
      <div className="col-12 col-lg-6 py-3">
        <StaticImage
          src="../../../../static/herosectionIm.png"
          alt={`IBM Maximo: ${data.title1} ${data.title2}`}
          title={`IBM Maximo: ${data.title1} ${data.title2}`}
          placeholder="tracedSVG"
          className="img-fluid"
        />
      </div>
    </div>
  )
}

export default Presentation
